<template>
  <div
    @click="closePopUp()"
    class="fixed w-full h-full transparent-background blurry-background"
  ></div>
  <div class="fixed w-11/12 md:w-1/2 bg-white top-1/3 left-4 md:left-1/3 p-5">
    <div class="flex justify-between items-center">
      <h1 class="text-xl font-semibold">Member</h1>
      <p @click="closePopUp()" class="text-2xl font-bold cursor-pointer">
        &times;
      </p>
    </div>
    <div class="mt-2" style="border-top: 1px solid #ccc"></div>
    <h1 class="mt-5 font-bold">Associated Profiles</h1>
    <div class="mt-4">
      <div
        v-for="profile in profiles"
        :key="profile.id"
        class="flex justify-between items-center border py-4 px-5"
      >
        <p class="font-semibold">{{ profile.username }}</p>
        <div class="flex items-center p-2" @click="editProfileRequest(profile)">
          <div class="bg-brightGray flex items-center p-2 cursor-pointer">
            <div
              class="circle"
              :style="{
                'background-color': profile.is_active ? 'green' : 'grey',
              }"
            ></div>
            <p class="ml-3 font-semibold">Active</p>
          </div>
          <div class="bg-brightGray flex items-center ml-5 p-2 cursor-pointer">
            <div
              class="circle"
              :style="{
                'background-color': !profile.is_active ? 'red' : 'grey',
              }"
            ></div>
            <p class="ml-3 font-semibold">Inactive</p>
          </div>
        </div>
      </div>
    </div>
    <Alert :alert="alert" />
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import {
  getDependents,
  activateDeactivateProfile,
} from "@/services/user/user.js";
import Alert from "@/components/general/Alert";

const router = useRouter();
const route = useRoute();
const profiles = ref([]);
const alert = ref({ message: "", active: false, error: false });

onMounted(async () => {
  const id = route.params.id;

  const response = await getDependents(id);
  profiles.value = response.data;
});

function closePopUp() {
  router.back();
}

async function editProfileRequest(profile) {
  const activeStatus = profile.is_active;

  try {
    await activateDeactivateProfile(profile.id, !activeStatus);

    profile.is_active = !activeStatus;
    handleAlert("Profie edited successfully", false);
  } catch (error) {
    handleAlert("There are no seats available", true);
  }
}

function handleAlert(message, error) {
  alert.value = { message, active: true, error };
  setTimeout(() => {
    alert.value.active = false;
  }, 3000);
}
</script>

<style scoped>
.blurry-background {
  filter: blur(5px);
}

.transparent-background {
  background: rgba(0, 0, 0, 0.5);
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
